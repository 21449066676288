import { NextRouter, useRouter } from "next/router";
import { useContext, useEffect } from "react";
import { addAccessToken, extendAPI } from "@gocardless/api/utils/api";
import { billingRequestFlowInitialise } from "@gocardless/api/dashboard/billing-request-flow";
import { billingRequestTemplateUse } from "@gocardless/api/dashboard/billing-request-template";
import { useI18n } from "src/common/i18n";
import { Logger } from "src/common/logger";
import { ErrorsEnum } from "src/state/errors";
import { getIdFromQueryParameter, showError } from "src/common/utils";
import { Event, EventName, postEvent, waitEvent } from "src/dropin/events";

import { useRhinoState } from "./useRhinoState";
import { RuntimeMode } from "./RuntimeModeInitialiser";

import { GlobalState, PayerThemeType } from ".";

export const SessionInitialiser = () => {
  const router = useRouter();

  return router.isReady ? <SessionInitialiserReady router={router} /> : null;
};

export const SessionInitialiserReady = ({ router }: { router: NextRouter }) => {
  const {
    setError,
    billingRequestFlow,
    runtimeMode,
    setBillingRequestFlow,
    setShowEmailVerification,
    setBillingRequestId,
    setDropinOrigin,
    setPayerTheme,
  } = useContext(GlobalState);

  const billingRequestFlowId = getIdFromQueryParameter(router.query?.id);
  const billingRequestTemplateId = getIdFromQueryParameter(
    router.query?.template
  );

  const [locale] = useI18n();

  const log = Logger("SessionInitialiser", {
    billing_request_flow_id: billingRequestFlowId,
  });

  // This calls the endpoint "/rhino-brf", which tells us whether a payer should be
  // tracked, and stores the result in localStorage.
  useRhinoState(billingRequestFlow?.id);

  // Only run this effect on page load
  useEffect(() => {
    // Early exit if we already have a billing request, as this means we have a
    // session token.
    if (billingRequestFlow) {
      return;
    }

    if (!billingRequestFlowId && typeof billingRequestTemplateId === "string") {
      log({
        message: "detected template ID, asking to use template",
      });
      billingRequestTemplateUse(billingRequestTemplateId).then(({ data }) => {
        // This should never happen.
        if (!data) {
          // Throw early since the next line would error anyway.
          throw new Error("no data returned from billingRequestTemplateUse");
        }
        const { billing_request_flow_id, billing_request_id } = data;
        log({
          message:
            "created new Billing Request and Flow from template, setting id query param",
          billing_request_template_id: billingRequestTemplateId,
          billing_request_flow_id,
          billing_request_id,
        });
        router.replace({
          pathname: router.pathname,
          query: {
            ...router.query,
            initial: router.route,
            id: billing_request_flow_id,
          },
        });
      });
      return;
    }

    // The first page load will have an undefined ID, which we'll set an error
    // in response to. We rely on the other useEffect to unset the error once we
    // establish a session.
    //
    // This is crap and we should change it.
    if (typeof billingRequestFlowId !== "string") {
      setError({
        errorType: ErrorsEnum.InvalidJWT,
        errorMessage: "SessionInitialiser: Initial page load error",
      });
      return;
    }

    // Add the header to ensure we can always track what billing request flow
    // we're making requests on behalf of.
    extendAPI({
      hooks: {
        beforeRequest: [
          (request) => {
            if (typeof billingRequestTemplateId === "string") {
              request.headers.set(
                "X-Billing-Request-Template",
                billingRequestTemplateId
              );
            }
            if (runtimeMode === RuntimeMode.Dropin) {
              request.headers.set("source", "dropin");
            }
            request.headers.set("X-Billing-Request-Flow", billingRequestFlowId);
          },
        ],
      },
    });

    log({
      message: "initialising session with billing request flow",
    });
    billingRequestFlowInitialise(billingRequestFlowId)
      .then((data) => {
        const brf = data.billing_request_flows;
        // This should never happen.
        if (!brf) {
          // Throw early since we'd throw a runtime error anyway.
          throw new Error(
            "got empty response from billing request flow initialise"
          );
        }
        log({
          message:
            "received a billing request flow from initialise, setting it in state",
        });
        setBillingRequestFlow(brf);

        const isDropin = runtimeMode === RuntimeMode.Dropin;
        const hasTemplate =
          Boolean(brf.config?.paylink?.id) ||
          Boolean(brf.config?.plan?.id) ||
          Boolean(brf.config?.authorisation_link?.id);

        if (brf.customer_details_captured && hasTemplate && !isDropin) {
          setShowEmailVerification(true);
        }

        if (brf.config?.payer_theme) {
          setPayerTheme(brf.config?.payer_theme as PayerThemeType);
          log({
            message: "Detected payertheme for merchant, setting it in state",
          });
        }
        // Only exit if we can't detect a parent- otherwise we should expect to
        // be running via the Dropin, and should start the authentication
        // handshake.
        if (!window.parent) {
          return null;
        }

        // We have a parent, so let's ensure the parent is legitimate.
        log({
          message:
            "we have a parent window, requesting the parent authenticates",
          event_name: EventName.AUTH_REQUEST,
        });
        // Be careful: we send this message with no identifying details, as we
        // want the client to respond with the Billing Request Flow ID to
        // confirm it was the frame that triggered the modal.
        postEvent({
          target: window.parent,
          event: {
            name: EventName.AUTH_REQUEST,
            payload: {},
          },
        });

        log({
          message: "waiting for Dropin client to send AUTH_CONFIRM",
        });
        return waitEvent({
          eventName: EventName.AUTH_CONFIRM,
          expectedOrigin: "*",
        }).then((event: Event) => {
          // We can authenticate via either the template ID or the flow ID.
          if (typeof billingRequestTemplateId === "string") {
            if (
              billingRequestTemplateId !==
              event.payload.billingRequestTemplateID
            ) {
              throw `received invalid ${EventName.AUTH_CONFIRM} event with mismatched template ID, cannot proceed`;
            }
          } else {
            const flowID = event.payload.billingRequestFlow?.id;
            if (flowID !== brf.id) {
              throw `received invalid ${EventName.AUTH_CONFIRM} event with mismatched flow ID, cannot proceed`;
            }
          }

          log({
            message:
              "Dropin client successfully authenticated, setting dropinOrigin",
            dropin_origin: event.payload.origin,
          });
          if (!event?.payload?.origin) {
            // This should never happen.
            throw new Error("unable to determine event origin.");
          }
          setDropinOrigin(event.payload.origin);
        });
      })
      .catch((error) => {
        log({
          message: "error initialising the billing request flow",
          error: error,
        });

        showError(error, setError, "SessionInitialiser");
      });
  }, [
    billingRequestFlow,
    billingRequestFlowId,
    billingRequestTemplateId,
    setPayerTheme,
  ]);

  useEffect(() => {
    if (!billingRequestFlow) return;
    if (!billingRequestFlow.session_token) return;

    log({
      message:
        "configuring API client with session token, and setting billing request",
      billing_request_id: billingRequestFlow.links?.billing_request,
    });

    // This configures the official API package, and should be all that
    // remains once we transition entirely to the codegen client.
    addAccessToken(billingRequestFlow.session_token);

    // Set our global billing request ID, so we can find our request
    setBillingRequestId(billingRequestFlow.links?.billing_request);

    // Unset the error set by the page load
    setError();
  }, [billingRequestFlow?.session_token]);

  // run this effect whenever the user locale changes
  useEffect(() => {
    if (!billingRequestFlow) return;
    extendAPI({
      hooks: {
        beforeRequest: [
          (request) => {
            request.headers.set("Accept-Language", locale);
          },
        ],
      },
    });
  }, [locale, billingRequestFlow]);

  return <></>;
};
